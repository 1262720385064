import { useEffect, useState } from 'react';
import { Requester } from '../helpers/Requester';
import { OrganizationDto } from '../models/OrganizationDto';
import { ChatbotRoutes } from '../helpers/Routes';
import { Button, Container, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';

export const OrganizationsPage = () => {
    const [organizationsRows, setOrganizationsRows] = useState<JSX.Element[]>([]);
    const [organizations, setOrganizations] = useState<OrganizationDto[]>([]);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedOrganizations = await Requester.getOrganizations();
            if (fetchedOrganizations === null) {
                setIsError(true);
                return;
            }

            setOrganizations(fetchedOrganizations);  
        };

        fetchData();
    }, []);

    useEffect(() => {
        const rows: JSX.Element[] = [];
        for (const organization of organizations) {
            rows.push(
                <TableRow>
                    <TableCell>{organization.id}</TableCell>
                    <TableCell>{organization.name}</TableCell>
                    <TableCell>
                        <Link to={ChatbotRoutes.updateOrganization(organization.id)}>
                            <Button color="secondary">Update</Button>
                        </Link>
                    </TableCell>
                </TableRow>,
            );
        }

        setOrganizationsRows(rows);
    }, [organizations]);

    if (isError) {
        return <p>Error</p>;
    }

    return (
        <Container>
            <h1>Organizations list</h1>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                {organizationsRows}
            </Table>

            <Link to={ChatbotRoutes.createOrganization}><Button color="secondary">Create</Button></Link>
        </Container>
    );
};