import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { Environment } from '../configuration/Environment';

export const AUTH_CONFIG: UserManagerSettings = initializeManagerSettings();


function initializeManagerSettings(): UserManagerSettings {
    const ssoDomain = Environment.getSsoDomain();
    const ssoClientId = Environment.getSsoClientId();

    return {
        authority: ssoDomain ?? '',
        client_id: ssoClientId ?? '',
        redirect_uri: `${window.location.origin}/callback`,
        automaticSilentRenew: true,
        silent_redirect_uri: `${window.location.origin}/callback`,
        post_logout_redirect_uri: `${window.location.origin}/callback/logout`,
        response_type: 'code',
        scope: 'profile openid email offline_access bsr-chatbot',
        loadUserInfo: true,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        metadata: {
            authorization_endpoint: `${ssoDomain}/connect/authorize`,
            end_session_endpoint: `${ssoDomain
                }/identity/account/logout?returnUrl=${encodeURIComponent(
                    window.location.origin,
                )}`,
            issuer: ssoDomain,
            token_endpoint: `${ssoDomain}/connect/token`,
            userinfo_endpoint: `${ssoDomain}/connect/userinfo`,
        },
    };
}