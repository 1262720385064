export const ChatbotRoutes = {
    chat: '/',
    authCallback: '/callback',

    createUser: '/create-user',
    updateUser: (id: string) => `/update-user/${id}`,
    updateUserTemplate: '/update-user/:id',
    users: '/users',

    createAssistant: '/create-assistant',
    updateAssistant: (id: string) => `/update-assistant/${id}`,
    updateAssistantTemplate: '/update-assistant/:id',
    assistants: '/assistants',

    createOrganization: '/create-organization',
    updateOrganization: (id: string) => `/update-organization/${id}`,
    updateOrganizationTemplate: '/update-organization/:id',
    organizations: '/organizations',
} as const;