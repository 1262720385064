import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { User } from 'oidc-client-ts';
import AuthContext from './AuthContext';
import useAuthService from './Auth';
import { Environment } from '../configuration/Environment';

const AuthProvider = ({ children }: PropsWithChildren<object>) => {
  const [loading, setLoading] = useState(false);
  const [userFromContext, setUserFromContext] = useState<User | null>(null);

  const {
    getUser,
    login,
    loginCallback,
    logout,
    renewToken,
    signinCallback,
    signinSilentCallback,
  } = useAuthService();

  useEffect(() => {
    getUserFromContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getUserFromContext = async (): Promise<void> =>
    getUser()
      .then(setUserFromContext)
      .finally(() => setLoading(false));

  const loginFromContext = async () => await login();

  const loginCallbackFromContext = async () => setUserFromContext(await loginCallback());

  const logoutFromContext = async () => await logout();

  const renewTokenFromContext = async () => setUserFromContext(await renewToken());

  const signinCallbackFromContext = async () => setUserFromContext(await signinCallback());

  const signinSilentCallbackFromContext = async () => setUserFromContext(await signinSilentCallback());

  const setClaimFromContext = useCallback(
    async (pid: string) => {
      const ssoDomain = Environment.getSsoDomain();  

      fetch(`${ssoDomain}/account/user/resource`, {
        method: 'PUT',
        headers: new Headers({
          Accept: 'application/json, application/jwt',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userFromContext?.access_token}`,
        }),
        body: JSON.stringify(pid),
      }).then(loginFromContext);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userFromContext],
  );

  return (
    <AuthContext.Provider
      value={{
        loading,
        userFromContext,
        getUserFromContext,
        loginFromContext,
        loginCallbackFromContext,
        logoutFromContext,
        renewTokenFromContext,
        setClaimFromContext,
        signinCallbackFromContext,
        signinSilentCallbackFromContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
