export class MessageDto {
  isUser: boolean;
  content: string;
  createdAt: number;

  constructor(data: { isUser: boolean, content: string, createdAt: number }) {
    this.isUser = data.isUser;
    this.content = data.content;
    this.createdAt = data.createdAt;
  }
}