
export class InitiateAssistantThreadResultDto {
  assistantId: string;
  threadId: string;
  initialMessage: InitialMessage;

  constructor(data: { 
    assistantId: string, 
    threadId: string, 
    initialMessage: InitialMessage }) 
  {
    this.assistantId = data.assistantId;
    this.threadId = data.threadId;
    this.initialMessage = data.initialMessage;
  }

  public static fromJsonObject(data: any): InitiateAssistantThreadResultDto { // eslint-disable-line @typescript-eslint/no-explicit-any -- we use any here for deserialization
    return new InitiateAssistantThreadResultDto({
      assistantId: data.assistantId,
      threadId: data.threadId,
      initialMessage: InitialMessage.fromJsonObject(data.initialMessage),
    });
  }
}

export class InitialMessage {
  text: string;

  constructor(data: { text: string }) {
    this.text = data.text;
  }

  public static fromJsonObject(data: any): InitialMessage { // eslint-disable-line @typescript-eslint/no-explicit-any -- we use any here for deserialization
    return new InitialMessage({
      text: data.text,
    })
  }
}