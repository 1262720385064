import { useEffect, useState } from 'react';
import { CreateUserDto, UserDto } from '../models/UserDto';
import { Requester } from '../helpers/Requester';
import { MenuItem, Select, TextField, Checkbox, Button, Container, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { OrganizationDto } from '../models/OrganizationDto';
import { useNavigate } from 'react-router-dom';
import { ChatbotRoutes } from '../helpers/Routes';

type UserRowParams = 
    | {
        userId: string,
        isCreation: false,
    }
    | {
        userId: null,
        isCreation: true,
    }

export const UserPage = (params: UserRowParams) => {
    const [user, setUser] = useState<(CreateUserDto & UserDto) | null>(null);
    const [isCreation] = useState(params.isCreation);
    const [organizations, setOrganizations] = useState<OrganizationDto[]>([]);
    const [organizationsMenuItems, setOrganizationsMenuItems] = useState<JSX.Element[]>([]);
    const [notFound, setNotFound] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const [userId, setUserId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userOrganization, setUserOrganization] = useState('');
    const [userAdmin, setUserAdmin] = useState(false);
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');

    const submit = async () => {
        const newUser = {
            id: userId,
            email: userEmail,
            organizationId: userOrganization,
            isAdmin: userAdmin,
            firstName: userFirstName,
            lastName: userLastName,
        };

        const action = isCreation 
            ? async (x: CreateUserDto & UserDto) => {
                const result = await Requester.createUser(x);
                if (result === null) {
                    return null;
                }

                return result.userId;
            }
            : async (x: CreateUserDto & UserDto) => {
                const result = await Requester.updateUser(x);
                if (result === null) {
                    return null;
                }

                return x.id;
            };

        const id = await action(newUser);
        if (id === null) {
            setIsError(true);
            return;
        }
        
        newUser.id = id;
        setUser(newUser);

        if (isCreation) {
            navigate(ChatbotRoutes.users, { replace: true });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const fetchedOrganizations = await Requester.getOrganizations();
            if (fetchedOrganizations === null) {
                setIsError(true);
                return;
            }

            const firstOrganization = fetchedOrganizations[0];
            if (firstOrganization === undefined) {
                setIsError(true);
                return;
            }

            setOrganizations(fetchedOrganizations);
            if (params.isCreation) {
                setUser({
                    id: '',
                    email: '',
                    isAdmin: false,
                    organizationId: firstOrganization.id,
                    firstName: '',
                    lastName: '',
                });
            }
            else {
                const fetchedUsers = await Requester.getUsers();
                if (fetchedUsers === null) {
                    setIsError(true);
                    return;
                }
    
                const fetchedUser = fetchedUsers.find(x => x.id === params.userId);
                if (fetchedUser === undefined) {
                    setNotFound(true);
                    return;
                }
    
                setUser({
                    firstName: '',
                    lastName: '',
                    ...fetchedUser,
                });
            }        
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (user === null) {
            return;
        }

        setUserId(user.id);
        setUserEmail(user.email);
        setUserOrganization(user.organizationId);
        setUserAdmin(user.isAdmin);
        setUserFirstName(user.firstName);
        setUserLastName(user.lastName);
    }, [user]);

    useEffect(() => {
        const organizationsItems: JSX.Element[] = [];
        for (const organization of organizations) {
            organizationsItems.push(
                <MenuItem value={organization.id}>{organization.name}</MenuItem>,
            );
        }

        setOrganizationsMenuItems(organizationsItems);
    }, [organizations]);

    if (user === null) {
        return <p>Loading...</p>;
    }

    if (isError) {
        return <p>Error</p>;
    }

    if (notFound) {
        return <p>Not found</p>;
    }

    return (
        <Container>
            <h1>{isCreation ? 'Create user' : `Update user ${user.email}`}</h1>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isCreation && 
                    <>
                    <TableRow>
                        <TableCell>First name</TableCell>
                        <TableCell>
                            <TextField 
                              value={userFirstName} 
                              required={true} 
                              onChange={e => setUserFirstName(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Last name</TableCell>
                        <TableCell>
                            <TextField 
                              value={userLastName} 
                              required={true} 
                              onChange={e => setUserLastName(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    </>}
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>
                            <TextField 
                              value={userEmail} 
                              required={true} 
                              onChange={e => setUserEmail(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Organization</TableCell>
                        <TableCell>
                            <Select 
                              value={userOrganization} 
                              required={true} 
                              onChange={e => setUserOrganization(e.target.value)}
                              style={{width: '100%'}}
                            >
                                {organizationsMenuItems}
                            </Select>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Has admin permissions</TableCell>
                        <TableCell>
                            <Checkbox 
                              checked={userAdmin} 
                              onChange={e => setUserAdmin(e.target.checked)} />
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Button color="secondary" onClick={submit}>Submit</Button>
        </Container>
    );
};