import Popup from 'reactjs-popup';
import { ChatbotRoutes } from '../helpers/Routes';
import { Container, Link } from '@mui/material';

interface AdminPanelPopupParams {
    trigger: JSX.Element,
}

export const AdminPanelPopup = (params: AdminPanelPopupParams) => {
    const contentStyle = {
        maxWidth: '600px',
        width: '90%',
        'background-color': 'white',
        'border-style': 'solid',
    };

    return (
        <Popup 
          trigger={params.trigger} 
          position={'center center'}
          contentStyle={contentStyle}
          modal>
            <Container>
                <div className="modal">
                    <div className="header">
                        <h1>Admin panel</h1>
                    </div>
                    <div className="content">
                        <p><Link href={ChatbotRoutes.users}>Users management portal</Link></p>
                        <p><Link href={ChatbotRoutes.assistants}>Assistants management portal</Link></p>
                        <p><Link href={ChatbotRoutes.organizations}>Organizations management portal</Link></p>
                    </div>
                </div>
            </Container>
        </Popup>
    );
};