// src/components/Message.tsx
import React, { Fragment } from 'react';
import { MessageDto } from '../models/MessageDto';
import { Box, Avatar, Paper } from '@mui/material';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PersonIcon from '@mui/icons-material/Person';
import ReactMarkdown from 'react-markdown';

interface MessageProps {
  message: MessageDto;
}

function getTime(timestamp: number) {
  return new Date(timestamp * 1000).toLocaleString();
}

const Message: React.FC<MessageProps> = ({ message }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: message.isUser ? 'row-reverse' : 'row',
        alignItems: 'baseline',
        width: '100%',
      }}
    >
      <Avatar 
        sx={{ 
          bgcolor: message.isUser ? 'secondary.main' : 'primary.main' ,
          float: message.isUser ? 'right' : 'left',
        }}
      >
        {message.isUser ? <PersonIcon /> : <SmartToyIcon />}
      </Avatar>
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          ml: message.isUser ? 0 : 1,
          mr: message.isUser ? 1 : 0,
          backgroundColor: message.isUser ? 'secondary.light' : 'primary.light',
          color: '#ffffff',
          borderRadius: message.isUser ? '20px 5px 20px 20px' : '5px 20px 20px 20px',
        }}
      >
        {message.content.split('\n').map((text, index) => (
          <Fragment key={index}>
            <ReactMarkdown>{text}</ReactMarkdown>
          </Fragment> 
        ))}
        <Paper 
          sx={{
            color: '#fff', 
            fontSize: '10px', 
            backgroundColor: 'transparent', 
            marginTop: '6px', 
            width: 'fit-content', 
            float: 'right', 
            boxShadow: 'none'}}
          >
          {getTime(message.createdAt)}
        </Paper>
      </Paper>
    </Box>
  );
};

export default Message;