import { useEffect, useState } from 'react';
import { Requester } from '../helpers/Requester';
import { TextField, Button, Container, Table, TableHead, TableRow, TableCell, TableBody, Input } from '@mui/material';
import { OrganizationDto } from '../models/OrganizationDto';
import { useNavigate } from 'react-router-dom';
import { ChatbotRoutes } from '../helpers/Routes';

import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type OrganizationPageParams = 
    | {
        organizationId: string,
        isCreation: false,
    }
    | {
        organizationId: null,
        isCreation: true,
    }

export const OrganizationPage = (params: OrganizationPageParams) => {
    const [organization, setOrganization] = useState<OrganizationDto | null>(null);
    const [isCreation] = useState(params.isCreation);
    const [notFound, setNotFound] = useState(false);
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();

    const [organizationId, setOrganizationId] = useState(params.organizationId === null ? '' : params.organizationId);
    const [organizationName, setOrganizationName] = useState('');
    const [organizationSubscriptionCountPerPeriod, setOrganizationSubscriptionCountPerPeriod] = useState(0);
    const [organizationRemainingMessagesInPeriod, setOrganizationRemainingMessagesInPeriod] = useState(0);
    const [organizationTotalMessagesPerPeriod, setOrganizationTotalMessagesPerPeriod] = useState(0);
    const [organizationSubscriptionEndDate, setOrganizationSubscriptionEndDate] = useState(dayjs(new Date()));
    const [organizationPeriodEndDate, setOrganizationPeriodEndDate] = useState(dayjs(new Date()));

    const submit = async () => {
        const newOrganization: OrganizationDto = {
            id: organizationId,
            name: organizationName,
            periodEndDate: organizationPeriodEndDate.toDate(),
            remainingMessagesInPeriod: organizationRemainingMessagesInPeriod,
            subscriptionCountPerPeriod: organizationSubscriptionCountPerPeriod,
            subscriptionEndDate: organizationSubscriptionEndDate.toDate(),
            totalMessagesPerPeriod: organizationTotalMessagesPerPeriod,
        };

        const action = isCreation 
            ? async (x: OrganizationDto) => {
                const id = await Requester.createOrganization(x);
                if (id === null) {
                    return null;
                }

                return id;
            }
            : async (x: OrganizationDto) => {
                const id = await Requester.updateOrganization(x);
                if (id === null) {
                    return null;
                }

                return x.id;
            };

        const id = await action(newOrganization);
        if (id === null) {
            setIsError(true);
            return;
        }
        
        newOrganization.id = id;
        setOrganization(newOrganization);

        if (isCreation) {
            navigate(ChatbotRoutes.organizations, { replace: true });
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const fetchedOrganizations = await Requester.getOrganizations();
            if (fetchedOrganizations === null) {
                setIsError(true);
                return;
            }

            if (params.isCreation) {
                setOrganization({
                    id: '',
                    name: '',
                    periodEndDate: new Date(),
                    subscriptionEndDate: new Date(),
                    remainingMessagesInPeriod: 0,
                    subscriptionCountPerPeriod: 0,
                    totalMessagesPerPeriod: 0,
                });
            }
            else {
                const fetchedOrganizations = await Requester.getOrganizations();
                if (fetchedOrganizations === null) {
                    setIsError(true);
                    return;
                }
    
                const fetchedOrganization = fetchedOrganizations.find(x => x.id === params.organizationId);
                if (fetchedOrganization === undefined) {
                    setNotFound(true);
                    return;
                }
    
                setOrganization(fetchedOrganization);
            }        
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (organization === null) {
            return;
        }

        setOrganizationId(organization.id);
        setOrganizationName(organization.name);
        setOrganizationPeriodEndDate(dayjs(organization.periodEndDate));
        setOrganizationSubscriptionEndDate(dayjs(organization.subscriptionEndDate));
        setOrganizationRemainingMessagesInPeriod(organization.remainingMessagesInPeriod);
        setOrganizationTotalMessagesPerPeriod(organization.totalMessagesPerPeriod);
        setOrganizationSubscriptionCountPerPeriod(organization.subscriptionCountPerPeriod);
    }, [organization]);

    if (organization === null) {
        return <p>Loading...</p>;
    }

    if (isError) {
        return <p>Error</p>;
    }

    if (notFound) {
        return <p>Not found</p>;
    }

    return (
        <Container>
            <h1>{isCreation ? 'Create organization' : `Update organization ${organization.name}`}</h1>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>
                            <TextField 
                              value={organizationId} 
                              disabled={!isCreation}
                              required={true} 
                              onChange={e => setOrganizationId(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>
                            <TextField 
                              value={organizationName} 
                              required={true} 
                              onChange={e => setOrganizationName(e.target.value)}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Period end date</TableCell>
                        <TableCell>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker 
                                  value={organizationPeriodEndDate as any}
                                  onChange={e => setOrganizationPeriodEndDate(e as any)} />
                            </LocalizationProvider>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Subscription end date</TableCell>
                        <TableCell>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker 
                                  value={organizationSubscriptionEndDate as any}
                                  onChange={e => setOrganizationSubscriptionEndDate(e as any)} />
                            </LocalizationProvider>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total messages per period</TableCell>
                        <TableCell>
                            <Input 
                              value={organizationTotalMessagesPerPeriod} 
                              required={true} 
                              type='number'
                              onChange={e => setOrganizationTotalMessagesPerPeriod(parseInt(e.target.value))}
                              style={{width: '100%'}} />
                        </TableCell>
                    </TableRow>
                    {
                        !isCreation && 
                        <TableRow>
                            <TableCell>Remaining messages in period</TableCell>
                            <TableCell>
                                <Input 
                                  value={organizationRemainingMessagesInPeriod} 
                                  required={true} 
                                  type='number'
                                  onChange={e => setOrganizationRemainingMessagesInPeriod(parseInt(e.target.value))}
                                  style={{width: '100%'}} />
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
            <Button color="secondary" onClick={submit}>Submit</Button>
        </Container>
    );
};