// src/App.tsx
import { Suspense, useContext, useEffect, useState } from 'react';
import Chat from './components/Chat';
import { AuthContext, Callback } from './auth';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import { AUTH_CONFIG } from './auth/config';
import { UserPage } from './components/UserPage';
import { UsersPage } from './components/UsersPage';
import { ChatbotRoutes } from './helpers/Routes';
import { AssistantPage } from './components/AssistantPage';
import { AssistantsPage } from './components/AssistantsPage';
import { OrganizationPage } from './components/OrganizationPage';
import { OrganizationsPage } from './components/OrganizationsPage';

const App = () => {
  const { loginFromContext, renewTokenFromContext, logoutFromContext } = useContext(AuthContext);
  const [checkingSession, setCheckingSession] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('callback')) {
      setCheckingSession(false)
    } else {
      const oidc_data = localStorage.getItem(`oidc.user:${AUTH_CONFIG.authority}:${AUTH_CONFIG.client_id}`);
      if (oidc_data) {
        renewTokenFromContext().then((r) => { })
        .catch(() => {
          console.error;
          logoutFromContext();
        })
          .finally(() => {
            setCheckingSession(false);
          });
      } else {
        loginFromContext();
      }
    }
  }, [])

  if (checkingSession) {
    return <div>Loading...</div>;
  }

  return (
    <Suspense fallback={<>Loading</>}>
      <div className='App'>
        <main>
          <Routes>
            <Route path={ChatbotRoutes.authCallback} element={<Callback />} />
            <Route path={ChatbotRoutes.chat} element={<Chat />} />

            <Route path={ChatbotRoutes.users} element={<UsersPage />} />
            <Route path={ChatbotRoutes.createUser} element={<UserPage isCreation={true} userId={null} />} />
            <Route path={ChatbotRoutes.updateUserTemplate} element={<EditUserPage />} />

            <Route path={ChatbotRoutes.assistants} element={<AssistantsPage />} />
            <Route path={ChatbotRoutes.createAssistant} 
              element={
                <AssistantPage isCreation={true} assistantId={null} />
              } 
            />
            <Route path={ChatbotRoutes.updateAssistantTemplate} element={<EditAssistantPage />} />

            <Route path={ChatbotRoutes.organizations} element={<OrganizationsPage />} />
            <Route path={ChatbotRoutes.createOrganization} 
              element={
                <OrganizationPage isCreation={true} organizationId={null} />
              } 
            />
            <Route path={ChatbotRoutes.updateOrganizationTemplate} element={<EditOrganizationPage />} />
            <Route path='*' element={<>Not found</>} />
          </Routes>
        </main>
      </div>
    </Suspense>
  );
};

const EditUserPage = () => {
  const { id } = useParams();
  return <UserPage isCreation={false} userId={id!} />
};

const EditAssistantPage = () => {
  const { id } = useParams();
  return <AssistantPage isCreation={false} assistantId={id!} />
};

const EditOrganizationPage = () => {
  const { id } = useParams();
  return <OrganizationPage isCreation={false} organizationId={id!} />
}

export default App;
