import AuthContext from './AuthContext';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
  const navigate = useNavigate();
  const { loginCallbackFromContext } = useContext(AuthContext);

  useEffect(() => {
    loginCallbackFromContext().then(() => {
      navigate('/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Processing signin...</div>;
};

export default AuthCallback;
