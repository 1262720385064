export const Environment = {
    getBsrOpenAiApiBasePath: () => {
        return process.env.REACT_APP_BSR_OPENAI_API_BASE_PATH;
    },
    getSsoDomain: () => {
        return process.env.REACT_APP_SSO_DOMAIN;
    },
    getSsoClientId: () => {
        return process.env.REACT_APP_SSO_CLIENT_ID;
    },
    isStrictModeEnabled: () => {
        return process.env.REACT_APP_STRICT_MODE === 'true';
    },
    isProduction: () => {
        return process.env.REACT_APP_ENV === 'production';
    },
};