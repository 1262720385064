import { UserManager } from 'oidc-client-ts';
import { AUTH_CONFIG } from './config';

const useAuthService = () => {
  const userManager = new UserManager(AUTH_CONFIG);
  const getUser = () => userManager.getUser();
  const login = () => userManager.signinRedirect();
  const loginCallback = async () => await userManager.signinRedirectCallback();
  const logout = () => userManager.signoutRedirect();
  const renewToken = () => userManager.signinSilent();
  const signinCallback = () => userManager.signinRedirectCallback();
  const signinSilentCallback = () => userManager.signinSilent();

  return {
    getUser,
    login,
    loginCallback,
    logout,
    renewToken,
    signinCallback,
    signinSilentCallback,
  };
};

export default useAuthService;
