// src/components/Chat.tsx
import React from 'react';
import { Grid } from '@mui/material';
import { MessageDto } from '../models/MessageDto';
import Message from './Message';

interface MessageFeedProps {
  chatboxGridRef: React.MutableRefObject<HTMLDivElement>;
  messages: MessageDto[];
}

const MessageFeed: React.FC<MessageFeedProps> = ({ chatboxGridRef, messages }) => {
  return (
    <Grid 
      container 
      ref={chatboxGridRef} 
      direction="column" 
      spacing={2} 
      paddingBottom={2} 
      sx={{ 
        height: '75vh', 
        marginTop: 0, 
        flexFlow: 'column nowrap', 
        overflowY: 'scroll', 
        border: '1px solid rgba(0, 0, 0, 0.23)', 
        borderBottom: 'none', 
        position:'relative',
        backgroundColor: '#f4f4f4' }}
    >
      {messages.map((message, index) => (
        <Grid item paddingRight={2} alignSelf={message.isUser ? 'flex-end' : 'flex-start'} key={index} sx={{ width: '100%' }}>
          <Message key={index} message={message} />
        </Grid>
      ))}
    </Grid>
  );
};

export default MessageFeed;
