// src/components/Chat.tsx
import React from 'react';
import { Grid, TextField, LinearProgress, Button, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

interface InputProps {
    isWaiting: boolean;
    input: string;
    setInput: React.Dispatch<React.SetStateAction<string>>;
    handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    handleSendMessage: () => Promise<void>;
  }

const Input: React.FC<InputProps> = ({isWaiting, input, setInput, handleKeyPress, handleSendMessage}) => {
  return (
    <Grid 
      container 
      direction="row" 
      paddingBottom={5} 
      justifyContent={'space-between'} 
      sx={{ 'width': 'calc(100% + 16px)', marginLeft: '-16px' }}
    >
      <Grid item sm={11} xs={9}>
        <TextField
          label="Type your message"
          variant="outlined"
          disabled={isWaiting}
          autoComplete="off"
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyPress}
          sx={{borderRadius: '0 0 4px 4px'}}
        />
          {isWaiting && <LinearProgress color="inherit" />}
      </Grid>
      <Grid item sm={1} xs={3}>
        <Button 
          variant="contained" 
          fullWidth color="primary" 
          onClick={handleSendMessage} 
          disabled={isWaiting} 
          sx={{ height: 'calc(100% - 1px)' }}
        >
          {isWaiting && <CircularProgress color="inherit" />}
          {!isWaiting && <SendIcon fontSize="large" />}
        </Button> 
      </Grid>
    </Grid>
  );
};

export default Input;
