import { createContext } from 'react';
import { AuthContextType } from './types';

const AuthContext = createContext<AuthContextType>({
  loading: false,
  userFromContext: null,
  getUserFromContext: async () => { },
  loginFromContext: async () => { },
  loginCallbackFromContext: async () => { },
  logoutFromContext: async () => { },
  renewTokenFromContext: async () => { },
  signinCallbackFromContext: async () => { },
  signinSilentCallbackFromContext: async () => { },
  setClaimFromContext: async () => { },
});

export default AuthContext;
