// src/components/Chat.tsx
import { useContext, useEffect, useState } from 'react';
import { Grid, Button, Link, useMediaQuery, Box } from '@mui/material';
import { AssistantDto } from '../models/AssistantDto';
import { Requester } from '../helpers/Requester';
import { AuthContext } from '../auth';
import { AdminPanelPopup } from './AdminPanelPopup';

interface HeaderProps {
  assistantId: string | null
}

const Header = ({ assistantId }: HeaderProps) => {
  const { logoutFromContext } = useContext(AuthContext);
  const [assistant, setAssistant] = useState<AssistantDto | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const currentUser = await Requester.getCurrentUser();
      setIsAdmin(currentUser?.isAdmin ?? false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (assistantId === null) {
      return;
    }

    const initPopup = async () => {
      const assistantDetails = await Requester.getAssistant(assistantId);
      setAssistant(assistantDetails);
    };

    initPopup();
  }, [assistantId]);

  const onlySmallScreens = useMediaQuery('(min-width:992px)');

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      paddingBottom={0}
      paddingTop={2}
      paddingLeft={0}
      marginTop={2}
      sx={{
        textAlign: 'center',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderBottom: 0,
        borderRadius: '4px 4px 0 0',
        backgroundColor: '#373736',
      }}
    >
      <Grid
        paddingLeft={0}
        paddingBottom={0}
        alignItems="center"
        justifyContent="center"
        sx={{ position: 'relative' }}
      >
        <img src="https://marketresponse.nl/wp-content/uploads/sites/4/2023/09/MR-Product-logos-256x65px_BSR-by-MR-WIT-1.png" alt="BSR Market Response" />
        <Button
          sx={{ position: 'absolute', right: 16 }}
          variant={'contained'} color="secondary" onClick={logoutFromContext}>Logout</Button>
        {isAdmin && <AdminPanelPopup trigger={
          <Button
            sx={{ position: 'absolute', right: 150 }}
            variant={'contained'} color="secondary">Admin panel</Button>
        } />}
      </Grid>

      <Grid 
        paddingLeft={0} 
        paddingBottom={2}
        marginTop={0}
        alignItems="center" 
        justifyContent="center"
        >
          <span data-testid="assistant-name-header-text" style={{color: 'white'}}>{assistant?.friendlyName ?? 'Loading...'}</span>
      </Grid>

      <Grid sx={{ position: 'relative' }}>
        <Grid 
          sx={{ 
            position: 'absolute', 
            right: 16, 
            bottom: '18px', 
            fontSize: 'small', 
          }}>
          <Link href='mailto:support@marketresponse.nl?subject=BSR Chatbot' sx={{ color: 'rgb(95, 95, 94)', '&:hover': { color: 'white !important' } }}>
            <Box display={onlySmallScreens ? 'inline' : 'none'}>Hulp nodig? Contact </Box> support@marketresponse.nl
          </Link>
        </Grid>
        <Grid 
          sx={{ 
            position: 'absolute', 
            right: 16, 
            bottom: '32px', 
            fontSize: 'small', 
          }}>
          <Link target='_blank' href='https://web4.marketresponse.nl/q/1234581/index.php' sx={{ color: 'rgb(95, 95, 94)', '&:hover': { color: 'white !important' } }}>
            <Box display={onlySmallScreens ? 'inline' : 'none'}>Feedback? Klik hier</Box>
          </Link>
        </Grid>
      </Grid>

    </Grid>
  );
};

export default Header;
